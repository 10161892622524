<template>
  <q-select
    v-model="selected"
    :options="options"
    emit-value
    multiple
    use-input
    clearable
    v-bind="$attrs"
    @filter="filter"
  >
    <template v-if="selectedText" v-slot:selected>
      {{ selectedText }}
    </template>
    <template v-slot:option="option">
      <q-item v-bind="option.itemProps" v-on="option.itemEvents">
        <q-item-section side>
          <q-avatar square :color="option.itemProps.active ? 'primary' : 'grey-3'" size="sm">
            <q-icon name="check" />
          </q-avatar>
        </q-item-section>
        <q-item-section>
          {{ option.opt }}
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>
<script>
import Label from 'store/models/label'
export default {
  props: {
    value: Array
  },
  data () {
    return {
      labelFilter: null
    }
  },
  computed: {
    labels () {
      return Label.all()
    },
    options () {
      return this.labels.filter((label) => {
        return this.labelFilter?.length ? label.name.toLowerCase().includes(this.labelFilter.toLowerCase()) : true
      }).map(label => label.name)
    },
    selectedText () {
      return this.value?.length ? this.value.length > 1 ? this.$t('multiple_labels_selected') : this.value[0] : null
    },
    selected: {
      get () {
        return this.value
      },
      set (val) {
        if (val?.length < 1) val = null
        this.$emit('input', val)
        this.$emit('change', val)
      }
    }
  },
  created () {
    if (!this.labels.length) this.$store.dispatch('entities/labels/loadAllLabels')
  },
  methods: {
    filter (val, update) {
      update(() => {
        if (val) this.$store.dispatch('entities/labels/searchLabels', val)
        this.labelFilter = val
      })
    }
  }
}
</script>
